export const userImages = [
    // famous males
    'https://static.independent.co.uk/s3fs-public/thumbnails/image/2017/09/12/11/naturo-monkey-selfie.jpg',
    'http://thinkup.me/wp-content/uploads/2017/11/Jim_Carrey-1-e1510001639348.jpg',
    'https://cdn.onlyinyourstate.com/wp-content/uploads/2016/07/3630750300_f1cd14cdc3_b-700x875.jpg',
    'https://financialpostcom.files.wordpress.com/2015/01/nicolas-cage.jpg',
    'https://www.rd.com/wp-content/uploads/2014/04/12-famous-introverts-elton-john-sl.jpg',
    'https://img.buzzfeed.com/buzzfeed-static/static/2014-03/campaign_images/webdr05/4/20/71-famous-people-who-were-total-frat-boys-at-one--1-19771-1393983351-2_big.jpg',

    // famous females
    'https://media.giphy.com/media/26BkMUTMmscNygY2k/giphy.gif',
    'https://media.giphy.com/media/3ohhwkOxoNg56LQvvO/giphy.gif',
    'https://media.giphy.com/media/KaXENSCPjqnK0/giphy.gif',
    'https://media.giphy.com/media/l3vReaybWbUGEGXM4/giphy.gif',

    //cbb
    'https://media.giphy.com/media/ul1N76AMrZgwU/giphy.gif',
    'https://i.gifer.com/YHpe.gif',

    // animals
    'https://www.drusillas.co.uk/images/whats-on-card/redpanda-profile-400x400-984.jpg',
    'http://mymodernmet.com/wp/wp-content/uploads/2017/01/animal-selfies-thumbnail.jpg',
    'https://news.nationalgeographic.com/content/dam/news/2016/08/20/WAQ-big-eyes/01WAQbigeyes.ngsversion.1471633019887.adapt.1900.1.jpg',
    'https://www.wpcc.org.uk/images/nature/mammals/header-squirrel.jpg',
    'https://www.gvi.co.uk/wp-content/uploads/2016/05/2-1.png',
    'https://keyassets-p2.timeincuk.net/wp/prod/wp-content/uploads/sites/29/2015/01/iStock_000005542321_Medium.jpg',
    'https://media.giphy.com/media/l0HlGd1H6XkdlfjZS/giphy.gif',
    'https://media.giphy.com/media/3o6Zt7LhVoifX4ZpVm/giphy.gif',
    'https://media.giphy.com/media/3o7btMUhSRJdn6yPVS/giphy.gif',

    // characters
    'https://media.giphy.com/media/EJmocpLzsUAzm/giphy.gif',
    'https://www.funstockretro.co.uk/news/wp-content/uploads/2016/09/earthwormjim.png',
    'https://media.giphy.com/media/edMIP488DQ2d2/giphy.gif',
    'https://media.giphy.com/media/lOGoKhNgS3J16/giphy.gif',
    'https://media.giphy.com/media/3og0IwGidh5DYVDnzi/giphy.gif',
    'https://media.giphy.com/media/xUA7aTM6JBmolQBkdi/giphy.gif',
    'https://media.giphy.com/media/bjtY9tonevcSQ/giphy.gif',

    // misc
    'https://media.giphy.com/media/l0EoB1NXjUWjWPS48/giphy.gif',
    'https://media.giphy.com/media/xUA7bdB9e31U7ywal2/giphy.gif',
    'https://media.giphy.com/media/l0IylRNh2k3xJTU9W/giphy.gif',
    'https://media.giphy.com/media/xT39CYkPk4QyvZRmEM/giphy.gif',
    'https://media.giphy.com/media/3oKIPbKvfVzRxp5M0U/giphy.gif',
];
